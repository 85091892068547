import * as _ from "lodash";
import moment from "moment";
import { deserializeRelations } from "../../utils/common.js";

export const hotspot_mode = [
  { name: "Disabled", value: "disabled" },
  { name: "Links", value: "links" },
  { name: "Products without links", value: "products_without_link" },
  { name: "Products with links", value: "products_with_link" }
];

export const formStruct = {
  id: null,
  shop: null,
  brochure_categories: [],
  brochure_toplevel: [],
  brochure_afterpage: [],
  customer_level: 4,
  name: "",
  original_link: "",
  utm_campaign: "",
  notify: false,
  additional_ga_tracking_id: "",
  hotspot_mode: hotspot_mode[0],
  language: null,
  active_from: null,
  expire_after: null,
  published_at: null,
  brochure_regions: []
};

export function cloneFormStruct() {
  return _.cloneDeep(this.formStruct);
}

export function serialize(brochure, context) {
  var params = this.cloneFormStruct();
  params.id = brochure.id;
  params.active_from = brochure.active_from
    ? moment.unix(brochure.active_from)
    : null;
  params.expire_after = brochure.expire_after
    ? moment.unix(brochure.expire_after)
    : null;
  params.published_at = brochure.published_at
    ? moment.unix(brochure.published_at)
    : null;
  params.name = brochure.name;
  params.original_link = brochure.original_link;
  params.hotspot_mode = this.hotspot_mode.find(
    (el) => el.value == brochure.hotspot_mode
  );
  params.utm_campaign = brochure.utm_campaign;
  params.notify = brochure.notify;
  params.append_original_link = false;
  params.brochure_categories = brochure.brochure_categories;
  params.brochure_toplevel = brochure.brochure_toplevel;
  params.brochure_afterpage = brochure.brochure_afterpage;
  params.brochure_regions = brochure.regions;
  params.shop = brochure.shop;
  params.language = context.$store.getters.getLanguageById(
    brochure.language_id
  );
  params.regions = brochure.regions;
  params.customer_level = brochure.customer_level;
  return params;
}

export function deserialize(data, attachments, item = {}) {
  var params = {
    id: data.id,
    active_from: data.active_from,
    expire_after: data.expire_after,
    published_at: data.published_at,
    name: data.name,
    shop_id: data.shop.id,
    language_id: data.language.id,
    original_link: data.original_link,
    hotspot_mode: data.hotspot_mode.value,
    utm_campaign: data.utm_campaign,
    notify: data.notify,
    append_original_link: data.append_original_link,
    customer_level: data.customer_level,
    brochure_regions: data.brochure_regions.map((reg) => {
      return { id: reg.id };
    }),
    brochure_categories: deserializeRelations(
      data.brochure_categories,
      item.brochure_categories,
      "category",
      "brochure_category_id"
    ),
    brochure_toplevel: deserializeRelations(
      data.brochure_toplevel,
      item.brochure_toplevel,
      "category",
      "brochure_toplevel_id"
    ),
    brochure_afterpage: deserializeRelations(
      data.brochure_afterpage,
      item.brochure_afterpage,
      "category",
      "brochure_afterpage_id"
    )
  };

  for (let region_id in attachments) {
    if (data.brochure_regions.length) {
      let index = _.findIndex(data.brochure_regions, (reg) => {
        return reg.id == region_id;
      });

      if (index != -1) {
        params.brochure_regions[index] = {
          id: params.brochure_regions[index].id,
          pdf_id: attachments[region_id].id
        };
      } else {
        params.brochure_regions.push({
          region_id: region_id,
          pdf_id: attachments[region_id].id
        });
      }
    } else {
      params.brochure_regions.push({
        region_id: region_id,
        pdf_id: attachments[region_id].id
      });
    }
  }

  return params;
}

export const BROCHURES_SORT_OPTIONS = [
  { text: "No order", value: undefined },
  { text: "Publication Date/Time", value: "published_at" },
  { text: "Valid till", value: "expire_after" },
  { text: "Valid from", value: "active_from" }
];

export const BROCHURE_STATUSES = [
  "INBOX",
  "READY_FOR_APPROVAL",
  "WAITING_FOR_PUBLICATION",
  "ACTIVE",
  "EXPIRED",
  "ARCHIVE",
  "TRASH"
];

export const COUNTER_NAME = {
  INBOX: "inbox",
  READY_FOR_APPROVAL: "ready_for_approval",
  WAITING_FOR_PUBLICATION: "not_published",
  ACTIVE: "active",
  EXPIRED: "expired",
  ARCHIVE: "archived",
  TRASH: "trash"
};

export const FRONTEND_TO_BACKEND_STATUSES = {
  INBOX: "inbox",
  READY_FOR_APPROVAL: "for_approval",
  WAITING_FOR_PUBLICATION: "not_published",
  ACTIVE: "active",
  EXPIRED: "expired",
  ARCHIVE: "archived",
  TRASH: "trash"
};

export const BACKEND_BROCHURE_STATUSES = {
  processing: 0,
  inbox: 1,
  for_approval: 2,
  active: 3,
  published: 3,
  trash: 4,
  not_published: 5,
  expired: 6,
  archived: 6
};
